<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    :showCreateButton="isAvailableEdit"
    :showEditButton="isAvailableEdit"
    @create="onCreate('EmploymentCalendarForCurrentUserNew')"
    @edit="(id) => onEdit('EmploymentCalendarForCurrentUserGraphEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'EmploymentCalendarForCurrentUser',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'EmploymentCalendarForCurrentUser',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'Мой график работы',
        },
      ],
      tableCaption: 'Мой график работы',
      tableHeaders: [
        { text: 'Автор', order: 'author', alias: 'author' },
        { text: 'Период', order: 'period', alias: 'period' },
        { text: 'Тип', order: 'type', alias: 'type' },
        { alias: 'actions' },
      ],
    };
  },

  computed: {
    isAvailableEdit() {
      return this.$ability.checkPermissions(
        'Table',
        'EmploymentCalendarForCurrentUser',
        'Edit',
      );
    },
  }
};
</script>
