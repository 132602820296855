import { aeroApiMng } from '@/plugins/axios.js';

const getByMonth = (payload) => {
  return aeroApiMng.post(
    `/EmploymentCalendar/Month`,
    payload,
  );
};

const saveByMonth = (payload) => {
  return aeroApiMng.post(
    `/EmploymentCalendar/SaveMonth`,
    payload,
  );
};

const getByMonthForCurrentUser = (payload) => {
  return aeroApiMng.post(
    `/EmploymentCalendarForCurrentUser/Month`,
    payload,
  );
};

const saveByMonthForCurrentUser = (payload) => {
  return aeroApiMng.post(
    `/EmploymentCalendarForCurrentUser/SaveMonth`,
    payload,
  );
};

export default {
  getByMonth,
  saveByMonth,
  getByMonthForCurrentUser,
  saveByMonthForCurrentUser,
};
