import commonApi from '@/api/common.js';
import lowercaseFirstLetter from '@/utils/lowercaseFirstLetter.js';

const state = {
  gisTypes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  initiationStatuses: {
    isLoading: false,
    data: [],
    errors: null,
  },

  performers: {
    isLoading: false,
    data: [],
    errors: null,
  },

  pools: {
    isLoading: false,
    data: [],
    errors: null,
  },

  customers: {
    isLoading: false,
    data: [],
    errors: null,
  },

  accounts: {
    isLoading: false,
    data: [],
    errors: null,
  },

  types: {
    isLoading: false,
    data: [],
    errors: null,
  },

  priorities: {
    isLoading: false,
    data: [],
    errors: null,
  },

  periods: {
    isLoading: false,
    data: [],
    errors: null,
  },

  dayWeeks: {
    isLoading: false,
    data: [],
    errors: null,
  },

  platforms: {
    isLoading: false,
    data: [],
    errors: null,
  },

  callResults: {
    isLoading: false,
    data: [],
    errors: null,
  },

  employeeAccounts: {
    isLoading: false,
    data: [],
    errors: null,
  },

  customerAccounts: {
    isLoading: false,
    data: [],
    errors: null,
  },

  permissions: {
    isLoading: false,
    data: [],
    errors: null,
  },

  roles: {
    isLoading: false,
    data: [],
    errors: null,
  },

  periodSupportGisTypes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  periodicity: {
    isLoading: false,
    data: [],
    errors: null,
  },

  organizationsRoles: {
    isLoading: false,
    data: [],
    errors: null,
  },

  organizationsRegions: {
    isLoading: false,
    data: [],
    errors: null,
  },

  helpMenuTopItems: {
    isLoading: false,
    data: [],
    errors: null,
  },

  reminderRelatedObjectTypes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  telemarketingTasksRejectionReasons: {
    isLoading: false,
    data: [],
    errors: null,
  },

  telemarketingProducts: {
    isLoading: false,
    data: [],
    errors: null,
  },

  telemarketingScriptsForProduct: {
    isLoading: false,
    data: [],
    errors: null,
  },

  zadarmaCallsTypes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  zadarmaCallsStatuses: {
    isLoading: false,
    data: [],
    errors: null,
  },

  departments: {
    isLoading: false,
    data: [],
    errors: null,
  },

  employeeRoles: {
    isLoading: false,
    data: [],
    errors: null,
  },

  chatbotAnswers: {
    isLoading: false,
    data: [],
    errors: null,
  },

  startPages: {
    isLoading: false,
    data: [],
    errors: null,
  },

  sites: {
    isLoading: false,
    data: [],
    errors: null,
  },

  technicalSupportHelpTreeNodes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  customPeriodicTaskModes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  telemarketingScripts: {
    isLoading: false,
    data: [],
    errors: null,
  },

  telemarketingAnswers: {
    isLoading: false,
    data: [],
    errors: null,
  },
  
  telemarketingQuestions: {
    isLoading: false,
    data: [],
    errors: null,
  },

  telemarketingSkipCustomersCondition: {
    isLoading: false,
    data: [],
    errors: null,
  },

  bases1CTypes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  bases1CUpdateTypes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  missedCallTemplatesGroups: {
    isLoading: false,
    data: [],
    errors: null,
  },

  callCenterBases: {
    isLoading: false,
    data: [],
    errors: null,
  },

  hosts: {
    isLoading: false,
    data: [],
    errors: null,
  },

  servers: {
    isLoading: false,
    data: [],
    errors: null,
  },

  oneCPinCodes: {
    isLoading: false,
    data: [],
    errors: null,
  },
  
  tarrifCalls: {
    isLoading: false,
    data: [],
    errors: null,
  },
  
  employeeCalendarTypes: {
    isLoading: false,
    data: [],
    errors: null,
  },

  askForMarkRobots: {
    isLoading: false,
    data: [],
    errors: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },
};

const actions = {
  getCommonList({ commit }, payload) {
    let key = lowercaseFirstLetter(payload.name);

    commit('SET_LOADING', { key, status: true });
    return (
      commonApi
        .getCommonList(payload)
        .then((data) => {
          commit('SET_DATA', { key, data });
          return data;
        })
        // .catch((error) => {
        //   commit('SET_ERROR', { key, error: error });
        // })
        .finally(() => {
          commit('SET_LOADING', { key, status: false });
        })
    );
  },
};

export default {
  name: 'common',
  namespaced: true,
  state,
  mutations,
  actions,
};
