import employmentCalendarApi from '@/api/employmentCalendar';

const state = {
  employmentCalendar: {
    isLoading: false,
    data: [],
    errors: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].errors = error;
  },
};

const actions = {

  getByMonth(context, payload) {
    return employmentCalendarApi
      .getByMonth(payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  saveByMonth(context, payload) {
    return employmentCalendarApi
      .saveByMonth(payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getByMonthForCurrentUser(context, payload) {
    return employmentCalendarApi
      .getByMonthForCurrentUser(payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  saveByMonthForCurrentUser(context, payload) {
    return employmentCalendarApi
      .saveByMonthForCurrentUser(payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  name: 'employmentCalendar',
  namespaced: true,
  state,
  mutations,
  actions,
};
