<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('AccountEmployees')"
    @cancel="onCancel('AccountEmployees')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'AccountEmployeeCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'AccountEmployees',
      pageHeader: 'Создание аккаунтов сотрудников',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Список сотрудников',
          route: { name: 'AccountEmployees' },
        },
        {
          text: 'Создание сотрудника',
        },
      ],
      initialForm: {
        isCustomer: false,
        login: '',
        password: '',
        fio: '',
        mail: '',
        rightCloseRequest: false,
        rightUpdTime: false,
        sendNotification: false,
        rightCustomer: false,
        kspReaded: false,
        kspUnreaded: false,
        hidePersonalData: false,
        voipUserName: '',
        voipPassword: '',
        voipHost: '',
        voipPort: '',
        voipNumber: null,
        dontNotifyNewReq: false,
        addHours: null,
        accessIp: '',
        isHasGisRights: false,
        isArchive: false,
        isPool: false,
        poolId: null,
        departmentId: null,
        employeeRoleId: null,
        forbiddenTransferRequestDirectly: false,
        // zadarma
        zadarmaSip: null,
        zadarmaKey: null,
        zadarmaSecret: null,
        // roles
        roles: [],
        mailBoxesSettings: [],
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      pools: (state) => state.common.pools,
      roles: (state) => state.common.roles,
      departments: (state) => state.common.departments,
      employeeRoles: (state) => state.common.employeeRoles,
    }),

    rolesList() {
      if (this.form?.roles === undefined) return [];

      const { roles } = this.form;
      const { data } = this.roles;

      const getRolename = (roleId) => {
        const item = data.find(({ id }) => id === roleId);
        return item !== undefined ? item.name : null;
      };

      return roles
        .map(({ id, roleId }) => ({ id, name: getRolename(roleId) }))
        .filter(({ name }) => name !== null);

      //
      // if (this.form?.roles && this.form.roles.length) {
      //   return this.form.roles.map((item) => ({
      //     id: item.id,
      //     name: this.roles.data.find((element) => element.id === item.roleId)
      //       .name,
      //   }));
      // }
      // return [];
    },

    mailBoxesSettings() {
      return this.form?.mailBoxesSettings || [];
    },
    mailBoxesSettingsTable() {
      return this.mailBoxesSettings.map((item) => {
        return {
          ...item,
          useSsl: item.useSsl ? 'Да' : 'Нет',
        };
      });
    },

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'checkbox',
              key: 'isCustomer',
              label: 'Поле для отбора только сотрудников',
            },
            { type: 'text', key: 'login', label: 'Логин' },
            { type: 'text', key: 'password', label: 'Пароль' },
            { type: 'text', key: 'fio', label: 'ФИО' },
            { type: 'text', key: 'mail', label: 'Почта' },
            {
              type: 'checkbox',
              key: 'rightCloseRequest',
              label: 'Закрытие заявок',
            },
            {
              type: 'checkbox',
              key: 'rightUpdTime',
              label: 'Изменение часов по заявке',
            },
            {
              type: 'checkbox',
              key: 'sendNotification',
              label: 'Оповещение чата',
            },
            {
              type: 'checkbox',
              key: 'rightCustomer',
              label: 'Полный доступ в ЛК',
            },
            { type: 'checkbox', key: 'kspReaded', label: 'КСП прочитан' },
            { type: 'checkbox', key: 'kspUnreaded', label: 'КСП непрочитан' },
            {
              type: 'checkbox',
              key: 'hidePersonalData',
              label: 'Скрывать номер в телемаркетинге',
            },
            { type: 'text', key: 'voipUserName', label: 'Телефония - имя' },
            { type: 'text', key: 'voipPassword', label: 'Телефония - пароль' },
            { type: 'text', key: 'voipHost', label: 'Телефония - хост' },
            {
              type: 'number',
              key: 'voipPort',
              label: 'Телефония - порт',
            },
            {
              type: 'integerNumber',
              key: 'voipNumber',
              label: 'Внутренний номер',
              attributes: {
                options: {
                  minimumValue: 0,
                  decimalPlaces: 0,
                  digitGroupSeparator: '',
                },
              },
            },
            {
              type: 'checkbox',
              key: 'dontNotifyNewReq',
              label: 'Не оповещать о новых заявках',
            },
            {
              type: 'number',
              key: 'addHours',
              label: 'Добавить к времени по UTC',
            },
            { type: 'text', key: 'accessIp', label: 'Доступ на сайт по IP' },
            {
              type: 'checkbox',
              key: 'isHasGisRights',
              label: 'Может редактировать данные по ГИС ЖКХ',
            },
            { type: 'checkbox', key: 'isArchive', label: 'Архивный сотрудник' },
            { type: 'checkbox', key: 'isPool', label: 'Является пулом' },
            {
              type: 'select',
              key: 'poolId',
              label: 'ID пула',
              list: this.pools.data,
            },
            {
              type: 'select',
              key: 'departmentId',
              label: 'Подразделение',
              list: this.departments.data,
            },
            {
              type: 'select',
              key: 'employeeRoleId',
              label: 'Роль сотрудника',
              list: this.employeeRoles.data,
            },
            {
              type: 'checkbox',
              label: 'Запрещена прямая передача заявки',
              key: 'forbiddenTransferRequestDirectly',
            },
          ],
        },
        {
          tab: 'Zadarma',
          form: [
            { type: 'text', key: 'zadarmaSip', label: 'Sip телофонии Zadarma' },
            {
              type: 'text',
              key: 'zadarmaKey',
              label: 'API Key телефонии Zadarma',
            },
            {
              type: 'text',
              key: 'zadarmaSecret',
              label: 'API Secret телефонии Zadarma',
            },
          ],
        },
        {
          tab: 'Роли пользователя',
          table: {
            caption: 'Роли пользователя',
            headers: [
              {
                text: 'Роль',
                order: '',
                alias: 'name',
              },
              {
                text: '',
                order: '',
                alias: 'actions',
              },
            ],
            items: this.rolesList,
            key: 'roles',
            modalFields: [
              {
                type: 'select',
                key: 'roleId',
                label: 'Роль',
                list: this.roles.data,
              },
            ],
            showEditButton: true,
            showDeleteButton: true,
            showCreateButton: true,
          },
        },
        {
          tab: 'Настройки почты',
          table: {
            caption: 'Настройки почты',
            headers: [
              {
                text: 'Название почты',
                order: '',
                alias: 'name',
              },
              {
                text: 'Сервер',
                order: '',
                alias: 'server',
              },
              {
                text: 'Имя пользователя',
                order: '',
                alias: 'userName',
              },
              {
                text: 'Пароль',
                order: '',
                alias: 'password',
              },
              {
                text: 'Порт',
                order: '',
                alias: 'port',
              },
              {
                text: 'Использовать SSL',
                order: '',
                alias: 'useSsl',
              },
              {
                text: '',
                order: '',
                alias: 'actions',
              },
            ],
            items: this.mailBoxesSettingsTable,
            key: 'mailBoxesSettings',
            modalFields: [
              {
                type: 'text',
                key: 'name',
                label: 'Название почты',
              },
              {
                type: 'text',
                key: 'server',
                label: 'Сервер',
              },
              {
                type: 'text',
                key: 'userName',
                label: 'Имя пользователя',
              },
              {
                type: 'text',
                key: 'password',
                label: 'Пароль',
              },
              {
                type: 'text',
                key: 'port',
                label: 'Порт',
              },
              {
                type: 'checkbox',
                key: 'useSsl',
                label: 'Использовать SSL',
              },
            ],
            showEditButton: true,
            showDeleteButton: true,
            showCreateButton: true,
          },
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const pools = this.getCommonList({ name: 'Pools' });
    const roles = this.getCommonList({ name: 'Roles' });
    const departments = this.getCommonList({ name: 'Departments' });
    const employeeRoles = this.getCommonList({ name: 'EmployeeRoles' });

    Promise.all([pools, roles, departments, employeeRoles]).then(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
