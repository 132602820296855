<template>
  <sm-page-loader v-if="isLoadingPage" />

  <div v-else>
    <sm-breadcrumbs class="editable-list__breadcrumbs" :items="breadcrumbs" />

    <h2 class="editable-item__header">
      {{ pageHeader }}
    </h2>

    <sm-tabs
      class="week-tabs"
      :tabs="weekTabs"
      @activeTab="handleTabClick"
    />
    <sm-datatable
      :headers="weeklyScheduleHeaders[currentWeekIndex] || []"
      :items="weeklyProcessedTableItems[currentWeekIndex] || []"
      :key="sheduleTableRenderKey"
    >

      <template v-for="(_, slot) in 8" v-slot:[`day${slot}`]="{ row }">
        <div v-bind:key="slot">
          <div class="input-container">
            <sm-input
              v-model="row[`day${slot}`][0]"
              class="schedule-input"
              mask="##:## - ##:##"
              placeholder="00:00 - 00:00"
              :disabled="row[`dayDisabled${slot}`]"
            />
          </div>
          <div>
            <sm-input
              v-model="row[`day${slot}`][1]"
              class="schedule-input"
              mask="##:## - ##:##"
              placeholder="00:00 - 00:00"
              :disabled="row[`dayDisabled${slot}`]"
            />
          </div>
        </div>
      </template>
    </sm-datatable>

    <div class="editable-item__form-button-group">
      <template v-if="hasPermissions">
        <sm-button
          class="editable-item__form-button"
          :isLoading="isLoadingSaveButton"
          @click="onSaveForm()"
          >Сохранить</sm-button
        >

        <sm-button
          class="editable-item__form-button"
          outline
          neutrall
          @click="onCancelForm"
          >Отменить</sm-button
        >
      </template>

      <sm-button v-else class="editable-item__form-button" @click="onCancelForm"
        >Назад</sm-button
      >
    </div>
  </div>
</template>
  
<script>
// vuex
import { mapActions } from 'vuex'; 
// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmTabs from '@/components/common/SmTabs.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import SmInput from '@/components/common/forms/SmInput.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
// utils
import { daysOfWeek, monthNames } from '@/utils/datepicker';
import {
  getMonthWithEnding,
  calculateFirstDayOfMonth,
  calculateNumberOfWeeks,
  calculateDaysInCurrentMonth,
} from '@/utils/dateCalculations';

export default {
  name: 'EmploymentCalendarForCurrentUserGraphEdit',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmTabs,
    SmDatatable,
    SmInput,
    SmButton,
  },

  data() {
    return {
      isLoadingPage: true,
      currentDate: null,
      currentYear: null,
      currentMonth: null,
      numberOfWeeks: null,
      firstDayOfMonth: null,
      currentWeekOfMonth: null,
      daysInCurrentMonth: null,
      currentMonthIndex: null,
      currentWeekIndex: 0,
      sheduleTableRenderKey: 0,
      scheduleData: null,
      showAddScheduleModal: false,
      isLoadingSaveButton: false,
      errors: []
    };
  },

  computed: {
    id() {
      return this.$route.params.id;
    },

    weeklyScheduleHeaders() {
      const totalDays = this.daysInCurrentMonth;
      const startOffset = this.firstDayOfMonth - 1;
      const weekDays = daysOfWeek;
      const currentMonth = monthNames[this.currentMonthIndex];

      return Array.from({ length: this.numberOfWeeks }, (_, week) => {
        const headers = this.createWeekHeaders(week, startOffset, totalDays, weekDays, currentMonth);
        return headers;
      });
    },

    weeklyProcessedTableItems() {
      const totalDays = this.daysInCurrentMonth;
      const startOffset = this.firstDayOfMonth - 1;
      
      return Array.from({ length: this.numberOfWeeks }, (_, week) => {
        return this.scheduleData?.list?.map((item) => {
          return this.processEmployeeSchedule(item, week, startOffset, totalDays);
        });
      });
    },

    hasPermissions() {
      return this.$ability.checkPermissions(
        'Table',
        'EmploymentCalendarForCurrentUser',
        'Edit',
      );
    },

    weekTabs() {
      const weeks = this.weeklyScheduleHeaders;
      const currentMonth = getMonthWithEnding(this.currentMonth);

      return weeks.map((headers) => {
        const firstDay = headers[0]?.currentDay;
        const lastDay = headers[headers.length - 1]?.currentDay;

        // Формируем текстовую строку для вкладки недели
        const weekText = firstDay === lastDay 
          ? `${firstDay}, ${currentMonth}` 
          : `${firstDay} - ${lastDay}, ${currentMonth}`;

        return { text: weekText };
      });
    },

    pageHeader() {
      return `Мой график работ на ${this.currentMonth} ${this.currentYear}`
    },

    breadcrumbs() {
      return [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'Мой график работы',
          route: { name: 'EmploymentCalendarForCurrentUser' },
        },
        {
          text: this.pageHeader,
        },
      ];
    },


    isValidTimeRange() {
      return (start, end) => {
        const startMoment = this.$moment(start, 'HH:mm', true);
        const endMoment = this.$moment(end, 'HH:mm', true);
        if (!startMoment.isValid() || !endMoment.isValid()) {
          return false;
        }

        return endMoment.isSameOrAfter(startMoment);
      };
    },

    validateForm() {
      this.getTimeValidationErrors();
      const formattedErrorMessage = this.errors.join('\n').replace(/\n/g, '<br>');
      if (this.errors.length > 0) {
        return {
          isValid: false,
          errorMessage: formattedErrorMessage,
        };
      }
      return { isValid: true, errorMessage: '' };
    },
  },

  async created() {
    this.isLoadingPage = true;

    try {
      const { data } = await this.getByMonth({ listId: this.id });
      this.scheduleData = data;
      if (!this.scheduleData?.list?.length) {
        this.scheduleData.list.push({ schedule: [] });
      }
      const { period } = data;

      this.currentDate = this.parseDate(period + '');
      this.currentYear = this.currentDate.getFullYear();
      this.currentMonth = monthNames[this.currentDate.getMonth()];
      this.firstDayOfMonth = calculateFirstDayOfMonth(this.currentDate);
      this.numberOfWeeks = calculateNumberOfWeeks(this.currentDate);
      this.daysInCurrentMonth = calculateDaysInCurrentMonth(this.currentDate);
      this.currentMonthIndex = this.currentDate.getMonth();
    } catch (error) {
      console.error('Ошибка получения данных', error)
    } finally {
      this.isLoadingPage = false;
    }
  },

  methods: {
    ...mapActions({
      getByMonth: 'employmentCalendar/getByMonthForCurrentUser',
      saveByMonth: 'employmentCalendar/saveByMonthForCurrentUser',
    }),

    handleTabClick(index) {
      this.currentWeekIndex = index;
      this.sheduleTableRenderKey += 1;
    },

    parseDate(dateString) {
      const year = parseInt(dateString.substring(0, 4), 10);
      const month = parseInt(dateString.substring(4), 10) - 1;
      return new Date(year, month);
    },

    transformScheduleData(input) {
      const scheduleMap = input.reduce((acc, week) => {
        week.forEach((employee) => {
          const { accountId } = employee;

          if (!acc[accountId]) {
            acc[accountId] = { accountId, schedule: [] };
          }

          Array.from({ length: 7 }).forEach((_, dayIndex) => {
            const dayKey = `day${dayIndex + 1}`;
            const periods = employee[dayKey];

            if (periods) {
              acc[accountId].schedule.push({
                day: acc[accountId].schedule.length + 1,
                periods,
              });
            }
          });
        });

        return acc;
      }, {});

      return Object.values(scheduleMap);
    },

    getTimeValidationErrors() {
      if (!Array.isArray(this.weeklyProcessedTableItems)) return;

      this.weeklyProcessedTableItems.forEach((week, weekIndex) => {
        week.forEach((item, itemIndex) => {
          this.validateItemTimePeriods(item, itemIndex, weekIndex);
        });
      });
    },

    validateItemTimePeriods(item, itemIndex, weekIndex) {
      Array.from({ length: 7 }).forEach((_, dayIndex) => {
        const day = dayIndex + 1;
        const timePeriods = item[`day${day}`] || [];
        this.validateTimePeriods(timePeriods, day, itemIndex, weekIndex);
      });
    },

    validateTimePeriods(timePeriods, day, itemIndex, weekIndex) {
      timePeriods.forEach((period) => {
        if (!period) return;

        const [start, end] = period.split(' - ');
        if (this.isValidTimeRange(start, end)) return;

        this.errors.push(
          `Неверный диапазон времени для ${period} в неделе ${weekIndex + 1}, день ${day}.`
        );
      });
    },

    onSaveForm() {
      if (!this.isFormValid()) return;

      this.isLoadingSaveButton = true;

      const list = this.transformScheduleData(this.weeklyProcessedTableItems);
      const payload = { ...this.scheduleData, list };

      this.saveScheduleData(payload)
        .then(this.handleSaveSuccess)
        .catch(this.handleSaveError)
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    },
      // Вспомогательные функции
    isFormValid() {
      const { isValid, errorMessage } = this.validateForm;
      if (!isValid) {
        this.showErrorNotification(errorMessage);
        this.errors = [];
        return false;
      }
      return true;
    },

    showErrorNotification(message) {
      this.$notify({
        text: message,
        type: 'error',
        timer: 5000,
      });
    },

    saveScheduleData(payload) {
      return this.saveByMonth(payload);
    },

    handleSaveSuccess(result) {
      if (!result.data.isSucceed) return;
      this.$router.push({ name: 'EmploymentCalendarForCurrentUser' });
    },

    handleSaveError(error) {
      console.error('Ошибка сохранения данных:', error);
    },

    onCancelForm() {
      this.$router.push({ name: 'EmploymentCalendarForCurrentUser' });
    },

    // Вспомогательные функции
    createWeekHeaders(week, startOffset, totalDays, weekDays, currentMonth) {
      const headers = [];

      Array.from({ length: 7 }).forEach((_, dayIndex) => {
        const day = dayIndex + 1;
        const currentDay = week * 7 + day - startOffset;

        if (currentDay > 0 && currentDay <= totalDays) {
          const weekday = weekDays[(currentDay + startOffset - 1) % 7];
          headers.push({
            currentDay,
            text: `${weekday}, ${currentDay} ${getMonthWithEnding(currentMonth)}`,
            alias: `day${day}`,
          });
        }
      });

      return headers;
    },
    processEmployeeSchedule(item, week, startOffset, totalDays) {
      const schedule = {};

      Array.from({ length: 7 }).forEach((_, dayIndex) => {
        const day = dayIndex + 1;
        const currentDay = week * 7 + day - startOffset;
        const currentDate = this.$moment(this.currentDate).date(currentDay).startOf('day');

        if (currentDay > 0 && currentDay <= totalDays) {
          const daySchedule = item.schedule.find((s) => s.day === currentDay);
          schedule[`day${day}`] = daySchedule ? daySchedule.periods : [null, null];
          schedule[`dayDisabled${day}`] = !this.hasPermissions || currentDate.isBefore(this.$moment());
        }
      });

      return schedule;
    },
    
    formatEmployeeName(user) {
      if (!user) return '';
      const { name, position } = user;
      return `${name}${position ? ', ' + position : ''}`;
    },
  },
};
</script>

<style lang="scss">
.datatable__cell--day1,
.datatable__cell--day2,
.datatable__cell--day3,
.datatable__cell--day4,
.datatable__cell--day5,
.datatable__cell--day6,
.datatable__cell--day7 {
  padding: 0 !important;
}

.schedule-input .input,
.schedule-input .input:focus {
  border: 0;
  border-radius: unset;
}

.week-tabs,
.tabs {
  margin-bottom: 15px;
  background-color: none;
}

.top-panel {
  display: flex;
  align-items: center;
}

.input-container {
  border-bottom: 1px solid #99d9f4;
}

.editable-item__form-button-group {
  display: flex;
}

.editable-item__form-button {
  width: 180px;
  margin-right: 15px;

  &:last-of-type {
    margin-right: 0;
  }
}

.schedule-button-delete {
  height: 36px;
  width: 36px;
  padding: 0;
  border-radius: 5px;
  color: var(--white);
  background-color: var(--red);
  transition: background-color 0.2s;
}
</style>
  